import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";

const linkStyle = {
  width: 200,
  fontSize: 20,
  padding: 5,
  margin: 20,
  border: "2px solid gray",
  borderRadius: 5,
};
// const pageStyles = {
//   color: "#232129",
//   padding: 96,
//   fontFamily: "-apple-system, Roboto, sans-serif, serif",
// }

// const links = [
//   {
//     text: "Tutorial",
//     url: "https://www.gatsbyjs.com/docs/tutorial/",
//     description:
//       "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
//     color: "#E95800",
//   },
// ]

const IndexPage = () => {
  return (
    // <Layout>
    <div>
      <Link style={linkStyle} to="/property/stock">
        Housing Stock
      </Link>
      <Link style={linkStyle} to="/test">
        Test Page
      </Link>
    </div>
    // </Layout>

    // <main style={pageStyles}>
    //   <ul>
    //     {links.map(link => (
    //       <li key={link.url} style={{color: link.color }}>
    //         <span>
    //           <a
    //             href={`${link.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
    //           >
    //             {link.text}
    //           </a>
    //           {link.badge && (
    //             <span aria-label="New Badge">
    //               NEW!
    //             </span>
    //           )}
    //           <p>{link.description}</p>
    //         </span>
    //       </li>
    //     ))}
    //   </ul>
    // </main>
  );
};

export default IndexPage;

export const Head = () => <title>Home Page</title>;
